<template>
    <div id="app" :class="{ scroll: !isHomeRoute }">
        <header>
            <div class="left">
                <router-link to="/" class="title">上海别光动漫</router-link>
            </div>
            <div class="right">
                <div v-if="isLogin">
                    <span>{{ user }}</span>
                    <span style="margin: 0 15px"> | </span>
                    <el-link type="primary" @click="logout">退出登录</el-link>
                </div>
                <div v-else>
                    <router-link to="/login" class="btn-3d">登录</router-link>
                    <span style="margin: 0 15px"> | </span>
                    <router-link to="/register" class="btn-3d">注册</router-link>
                </div>
            </div>
        </header>
        <el-menu
            :default-active="activeIndex"
            :router="true"
            class="page-el-menu"
            mode="horizontal"
            background-color="#61b7e8"
            text-color="#ffffff"
        >
            <el-menu-item index="/">首页</el-menu-item>
            <el-menu-item index="/products/emoji">精品动漫</el-menu-item>
            <el-menu-item index="/products/comics">精品漫画</el-menu-item>
            <!-- <el-submenu index="/emojis">
                <template slot="title">精品动漫</template>
                <el-menu-item
                    v-for="(title, type, index) in emojiMap"
                    :key="index"
                    :index="`/emojis/${type}`"
                >
                    {{ title }}表情包
                </el-menu-item>
            </el-submenu>
            <el-submenu index="/comics">
                <template slot="title">精品漫画</template>
                <el-menu-item
                    v-for="(item, index) in comicTypes"
                    :key="index"
                    :index="`/comics/${item}`"
                >
                    {{ item }}漫画
                </el-menu-item>
            </el-submenu> -->
            <el-menu-item index="/user_disputes">用户纠纷的处理</el-menu-item>
            <el-menu-item index="/charging_standards">收费标准</el-menu-item>
            <el-menu-item index="/anime_information">动漫资讯</el-menu-item>
            <el-menu-item index="/business_cooperation">商务合作</el-menu-item>
            <el-menu-item index="/contact_us">联系我们</el-menu-item>
        </el-menu>
        <div class="container">
            <router-view />
        </div>
        <footer-vue v-if="!isHomeRoute" />
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    data() {
        return {
            activeIndex: "/",
        };
    },
    computed: {
        ...mapState(["comicTypes", "isLogin", "user"]),
        ...mapGetters(["emojiMap"]),
        isHomeRoute() {
            return this.activeIndex == "/";
        },
    },
    watch: {
        $route: {
            handler: function (newRoute) {
                this.activeIndex = newRoute.path;
            },
            immediate: true,
        },
    },
    methods: {
        logout() {
            this.$store.dispatch("logout");
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
* {
    box-sizing: border-box;
}
#app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(to right top, pink, skyblue 20%, #eee);
    header {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        // background: linear-gradient(45deg, red, #0ff, #42b983, salmon 50%, #efeeee 51%, #efeeee);

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }

        .title {
            font-size: 28px;
            // font-weight: bold;
            text-decoration: none;
        }

        .right {
            font-size: 16px;
            color: white;
            a {
                color: #aaa;
                text-decoration: none;
                font-family: "黑体";
                &:hover {
                    color: salmon;
                }
            }
        }
    }
    .page-el-menu {
        width: 100%;
        display: flex;
        justify-content: center;
        background: rgb(97, 183, 232);

        .el-menu-item,
        .el-submenu__title {
            font-size: 18px;
            font-weight: bold;
            i {
                color: white;
            }
        }
    }
    .container {
        padding: 10px 0;
    }
}
#app.scroll {
    overflow: auto;
}
</style>
