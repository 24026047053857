<template>
    <div class="login">
        <h3>注册新会员</h3>
        <p>
            注册时填写的Email就是您的账号，如果您已经注册了账号，则可以点此进行
            <router-link to="/login">登录</router-link>
        </p>

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="form">
            <el-form-item label="Email地址" prop="email">
                <el-input v-model="ruleForm.email" clearable placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="password">
                <el-input
                    v-model="ruleForm.password"
                    show-password
                    placeholder="请输入密码"
                ></el-input>
            </el-form-item>
            <el-form-item label="确认登录密码" prop="password_confirm">
                <el-input
                    v-model="ruleForm.password_confirm"
                    show-password
                    placeholder="请再次输入密码"
                ></el-input>
            </el-form-item>
            <el-form-item label="电话号码" prop="phone">
                <el-input
                    v-model="ruleForm.phone"
                    clearable
                    placeholder="请输入电话号码"
                ></el-input>
            </el-form-item>
            <el-form-item label="身份证" prop="id_card">
                <el-input
                    v-model="ruleForm.id_card"
                    clearable
                    placeholder="请输入身份证号码"
                ></el-input>
            </el-form-item>
            <el-form-item label="真实姓名" prop="real_name">
                <el-input
                    v-model="ruleForm.real_name"
                    clearable
                    placeholder="请输入真实姓名"
                ></el-input>
            </el-form-item>
            <el-form-item prop="protocol" style="text-align: left">
                <el-checkbox v-model="ruleForm.protocol">同意</el-checkbox>
                <router-link to="/protocol">服务协议</router-link>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import axios from "axios";

const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneReg = /^(?:(?:\+|00)86)?1\d{10}$/;
const idCardReg =
    /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;

export default {
    data() {
        return {
            loading: false,
            ruleForm: {
                email: "",
                password: "",
                password_confirm: "",
                phone: "",
                id_card: "",
                real_name: "",
                protocol: false,
            },
            rules: {
                email: [
                    { required: true, message: "请输入邮箱地址", trigger: "blur" },
                    {
                        validator: (rule, value, callback) => {
                            if (!emailReg.test(value)) {
                                callback(new Error("邮箱格式有误"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    { min: 6, max: 20, message: "密码长度在 6 到 20 个字符", trigger: "blur" },
                ],
                password_confirm: [
                    {
                        validator: (rule, value, callback) => {
                            if (value === "") {
                                callback(new Error("请再次输入密码"));
                            } else if (value !== this.ruleForm.password) {
                                callback(new Error("两次输入密码不一致!"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                phone: [
                    { required: true, message: "请输入电话号码", trigger: "blur" },
                    {
                        validator: (rule, value, callback) => {
                            if (!phoneReg.test(value)) {
                                callback(new Error("电话号码格式有误"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                id_card: [
                    { required: true, message: "请输入身份证号码", trigger: "blur" },
                    {
                        validator: (rule, value, callback) => {
                            if (!idCardReg.test(value)) {
                                callback(new Error("身份证号码格式有误"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                real_name: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
                protocol: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error("注册账号须同意服务协议"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.loading = true;
                    const res = await axios({
                        url: "/php/register.php",
                        method: "post", // 默认值
                        data: this.ruleForm,
                    }).then((res) => res.data);
                    this.loading = false;
                    if (res.code === 0) {
                        this.$message.info("注册成功");
                        localStorage.setItem("bjbk_user", this.ruleForm.email);
                        this.$router.push("/login");
                    } else {
                        this.$message.error(res.msg);
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    },
};
</script>

<style lang="scss" scoped>
.login {
    text-align: center;
    padding-bottom: 100px;
    .form {
        width: 500px;
        margin: 0 auto;
    }
}
</style>
