<template>
    <div class="view-wrap">
        <div class="view-title">商务合作</div>
        <el-row class="list">
            <el-col class="item" :span="12" v-for="(item, index) in list" :key="index">
                <el-card shadow="hover" style="cursor: pointer" @click.native="handleCp(item)">
                    <div class="content">
                        <img :src="`${$cdn}image/${item.icon}`" alt="" />
                        <div>
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.desc }}</p>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            list: [
                {
                    title: "内容合作",
                    icon: "cp1.jpg",
                    desc: "自有版权的优质小说内容，不限于长篇和短篇小说。",
                },
                {
                    title: "漫画合作",
                    icon: "cp2.jpg",
                    desc: "自有版权内容可改编成漫画，或拥有自行创作的漫画希望合作",
                },
                {
                    title: "动漫合作",
                    icon: "cp3.png",
                    desc: "自有版权内容可改编成动漫，或拥有自行创作的拥有版权的动漫作品希望合作。",
                },
                {
                    title: "短剧合作",
                    icon: "cp4.jpg",
                    desc: "自有版权内容可改编成短剧，或拥有自行创作的拥有版权的短剧作品希望合作。",
                },
                {
                    title: "游戏合作",
                    icon: "cp5.jpg",
                    desc: "自有版权内容可改编成游戏，或拥有自行创作的拥有版权的游戏作品希望合作。",
                },
            ],
        };
    },
    computed: {
        ...mapState(["isLogin"]),
    },
    methods: {
        handleCp(item) {
            if (!this.isLogin) {
                this.$message.info("请先登录");
                this.$router.push("/login");
            } else {
                this.$message.info(item.title);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.list {
    .item {
        margin-bottom: 50px;
        .content {
            display: flex;
            align-items: center;
            img {
                width: 30%;
                aspect-ratio: 1 / 1;
                object-fit: cover;
                margin-right: 15px;
                transition: all 0.3s linear;
            }

            &:hover {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }
    .item:nth-child(2n + 1) {
        padding-right: 30px;
    }
    .item:nth-child(2n) {
        padding-left: 30px;
    }
}
</style>
