import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "animate.css";
import "./public.scss";
import DynamicMarquee from "vue-dynamic-marquee";
import FooterVue from "./components/FooterVue.vue";

Vue.config.productionTip = false;

Vue.use(ElementUI);

Vue.component("dynamic-marquee", DynamicMarquee);
Vue.component("footer-vue", FooterVue);

Vue.prototype.$cdn = process.env.VUE_APP_CDNPREFIX;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
