<template>
    <div class="view-wrap">
        <div class="view-title">{{ emojiMap[type] }}表情包</div>
        <div class="emoji-list" v-if="emojiInfo">
            <dl class="emoji-item" v-for="index in emojiInfo.num" :key="type + index">
                <dt>
                    <img :src="`${$cdn}image/${type}/${index}.${emojiInfo.ext}`" />
                </dt>
                <dd>
                    <button class="btn-3d" @click="handleBeforeDownload(index)">下载</button>
                </dd>
            </dl>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    data() {
        return {
            type: "",
        };
    },
    computed: {
        ...mapState(["isLogin"]),
        ...mapGetters(["emojiMap"]),
        emojiInfo() {
            return this.$store.state.emojiTypes.filter((item) => item.type === this.type)[0];
        },
    },
    watch: {
        $route: {
            handler(route) {
                this.type = route.params.type;
                document.querySelector("#app").scrollTop = 0;
            },
            immediate: true,
        },
    },
    methods: {
        handleBeforeDownload(index) {
            if (!this.isLogin) {
                this.$message.info("请先登录");
                this.$router.push("/login");
            } else {
                // this.$router.push("/charging_standards");
                fetch(`/image/${this.type}/${index}.${this.emojiInfo.ext}`)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = `${this.type}/${index}.${this.emojiInfo.ext}`;
                        a.click();
                        URL.revokeObjectURL(url);
                    })
                    .catch((error) => {
                        console.error("下载图片时出错：", error);
                    });
            }
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.emoji-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background: #eee;
    .emoji-item {
        width: 33%;
        text-align: center;
        dt {
            img {
                max-width: 80%;
                object-fit: cover;
                aspect-ratio: 1 / 1;
            }
        }
        dd {
            margin: 10px 0;
            .btn {
                background: #db4e4f;
                height: 48px;
                width: 80px;
                outline: none;
                border: none;
                border-radius: 5px;
                color: white;
                font-size: 16px;
                cursor: pointer;
                &:hover {
                    background: #b63131;
                }
            }
        }
    }
    .btn-3d {
        width: 100px;
        height: 50px;
        color: salmon;
        font-weight: bold;
        font-size: 18px;
    }
}
</style>
