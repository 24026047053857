<template>
    <footer>
        <div class="footNav" style="text-align: center">
            <router-link to="/user_disputes" class="a_link">用户纠纷的处理</router-link>
        </div>
        <div class="copyRight" style="text-align: center">
            <p>上海别光科技有限公司</p>
            <p>地址：上海市奉贤区沿钱公路5601号1幢</p>
            <p>联系人: 赵小姐</p>
            <p>联系电话: 010-55138742</p>
            <p>
                <!-- <img :src="`${$cdn}image/gongan.png`" alt="" /> -->
                <!-- <span>京公网安备 11011402010827号</span> -->
                <a href="http://beian.miit.gov.cn/" target="_blank" class="a_link"
                    >沪ICP备2020027147号-1</a
                >
            </p>
            <p>© 上海别光 2020 All Rights Reserved.</p>
            <p>网文证编号：沪网文（2020）6084-415号</p>
        </div>
    </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
    padding: 50px 0;
    background: linear-gradient(45deg, #7678e6, #f4f9de);
    color: white;
    a {
        color: salmon;
        text-decoration: none;
        margin: 0 10px;
    }
}
</style>
