<template>
    <div class="view-wrap">
        <div class="view-title">用户纠纷的处理</div>
        <div class="content">
            <p>
                在注册、登陆本网站前请务必仔细阅读本《纠纷处理方式》（未成年人应当在其法定监护人陪同下阅读）中的各个条款，以确保您在享受本服务的同时维护自身合法权益：
            </p>
            <p>用户之间纠纷处理方式</p>
            <p>
                1.如若用户之间发生纠纷且无法协商一致的，可向国家相关行政机关或司法机关寻求解决，包括但不限于向公安机关报案、申请仲裁或向人民法院提起诉讼等。公司名称：上海别光科技有限公司将会为实名注册用户提供必要的协助和支持，并根据有关行政机关和司法机关的要求承担一定的举证责任或采取必要措施。
            </p>
            <p>
                2.用户之间发生纠纷的，也可向公司名称：上海别光科技有限公司的客服人员投诉并举证。用户需提供与其账号注册信息一致的个人有效身份证件、必要的国家行政或司法机关文件和其他公司名称：上海别光科技有限公司要求提供的相关证据。经公司名称：上海别光科技有限公司审核确认后，可以给予必要的协助和支持。
            </p>
            <p>3.用户与公司名称：上海别光科技有限公司之间纠纷处理方式</p>
            <p>
                如网站用户对公司名称：上海别光科技有限公司的服务有任何异议的，可以向公司名称：上海别光科技有限公司的客服人员投诉并举证。查证属实的，公司名称：上海别光科技有限公司将立即更正并按照现行法律规定给予用户必要的补偿。
            </p>
            <p>公司名称：上海别光科技有限公司</p>
            <p>公司地址：上海市奉贤区沿钱公路5601号1幢</p>
            <p>负责人: 赵小姐</p>
            <p>联系电话: 010-55138742 &nbsp;&nbsp;&nbsp; 18958044793</p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
