<template>
    <div class="view-wrap">
        <div class="view-title">
            <span>精品{{ title }}</span>
            <div class="line"></div>
        </div>

        <el-row class="p-list">
            <el-col
                class="p-item"
                :span="8"
                v-for="(item, index) in list"
                :key="`${type}-${index}`"
            >
                <template v-if="isEmoji">
                    <router-link :to="`/emojis/${item.type}`" style="text-decoration: none">
                        <img :src="`${$cdn}image/${item.type}/1.${item.ext}`" />
                        <p>
                            <span class="a_link">
                                {{ item.title }}
                            </span>
                        </p>
                    </router-link>
                </template>
                <template v-else>
                    <router-link :to="`/comics/${item}`" style="text-decoration: none">
                        <img
                            :src="`${$cdn}image/manhua/${item}/${item}-封面.jpg`"
                            style="object-position: center top"
                        />
                        <p>
                            <span class="a_link">
                                {{ item }}
                            </span>
                        </p>
                    </router-link>
                </template>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            type: "",
            typeMap: {
                emoji: "动漫",
                comics: "漫画",
            },
            list: [],
        };
    },
    computed: {
        ...mapState(["emojiTypes", "comicTypes", "isLogin"]),
        title() {
            return this.typeMap[this.type];
        },
        isEmoji() {
            return this.type === "emoji";
        },
    },
    watch: {
        $route: {
            handler(route) {
                this.type = route.params.type;
                if (this.isEmoji) {
                    this.list = this.emojiTypes;
                } else {
                    this.list = this.comicTypes;
                }
                document.querySelector("#app").scrollTop = 0;
            },
            immediate: true,
        },
    },
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.p-list {
    padding: 40px;
    .p-item {
        text-align: center;
        margin-bottom: 30px;
        padding: 10px;
        cursor: pointer;
        transition: all linear 0.2s;
        &:hover {
            transform: scale(1.1);
            box-shadow: 0 0 4px 4px salmon;
        }
        img {
            width: 60%;
            object-fit: cover;
            aspect-ratio: 1 / 1;
        }
    }
}
</style>
