<template>
    <div class="view-wrap">
        <div class="view-title">动漫资讯</div>
        <el-card>
            <div style="font-size: 18px; line-height: 30px; cursor: pointer">
                <p>
                    <el-link type="primary" @click="handlePay"
                        >2023 “白蕉海鲈” 动画短片创作大赛征集稿</el-link
                    >
                </p>
                <p>
                    <el-link type="primary" @click="handlePay">中国加油！漫画征稿活动</el-link>
                </p>
                <p>
                    <el-link type="primary" @click="handlePay"
                        >小花仙大电影发全新剧情长预告 夏磊配音曼陀罗王子</el-link
                    >
                </p>
                <p>
                    <el-link type="primary" @click="handlePay"
                        >漫荒推荐：画风超赞的国风漫画推荐 超长假期不无聊</el-link
                    >
                </p>
                <p>
                    <el-link type="primary" @click="handlePay"
                        >盘点好结局后宫番 当年这些后宫番 都是霸榜新番</el-link
                    >
                </p>
            </div>
            <div>
                <p style="text-align: center">
                    总计 5 个记录，共 1 页，当前第 1 页 |
                    <a>第一页</a>
                    &nbsp;
                    <span>上一页</span>
                    &nbsp;
                    <span>下一页</span>
                    &nbsp;
                    <a>最末页</a>
                </p>
            </div>
        </el-card>

        <el-dialog title="资讯付费" :visible.sync="showDialog" width="30%" center>
            <div style="text-align: center">
                <h3>资讯付费</h3>
                <p>本网站资讯，付费查看</p>
                <p>收费标准：<span>1元/次</span></p>
                <button class="btn" @click="showPay = true">确认付费</button>
            </div>

            <div slot="footer" class="dialog-footer"></div>
        </el-dialog>

        <el-dialog title="资讯付费" :visible.sync="showPay" width="30%" center>
            <div style="text-align: center">
                <img :src="`${$cdn}image/zfb-pay.jpg`" width="300" />
            </div>

            <div slot="footer" class="dialog-footer"></div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            showDialog: false,
            showPay: false,
        };
    },
    computed: {
        ...mapState(["isLogin", "user"]),
    },
    methods: {
        handlePay() {
            if (!this.isLogin) {
                this.$router.push("/login");
            } else {
                this.showDialog = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.btn {
    background: #db4e4f;
    height: 48px;
    width: 80px;
    outline: none;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    &:hover {
        background: #b63131;
    }
}
</style>
