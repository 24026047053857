<template>
    <div class="login">
        <h3>会员登录</h3>
        <p>登录后即可享受会员服务</p>

        <el-form label-width="100px" class="form">
            <el-form-item label="邮箱">
                <el-input v-model="form.email" clearable placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="form.password" show-password placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button class="btn-3d" plain :loading="loading" @click="submitForm"
                    >登录</el-button
                >
                <!-- <button type="submit" class="btn-3d" @click="submitForm">登录</button> -->
                <p>还没有账号？点此进行<router-link to="/register">注册</router-link></p>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import axios from "axios";

const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
    data() {
        return {
            loading: false,
            form: {
                email: "",
                password: "",
            },
        };
    },
    methods: {
        async submitForm() {
            if (!this.form.email) {
                this.$message("请输入邮箱");
                return;
            }
            if (!emailReg.test(this.form.email)) {
                this.$message("邮箱格式有误");
                return;
            }
            if (!this.form.password) {
                this.$message("请输入密码");
                return;
            }
            this.loading = true;
            const res = await axios({
                url: "/php/login.php",
                method: "post", // 默认值
                data: this.form,
            }).then((res) => res.data);
            this.loading = false;
            if (res.code === 0) {
                this.$message.info("登录成功");
                localStorage.setItem("bjbk_user", this.form.email);
                this.$store.dispatch("setLogin");
                this.$router.push("/");
            } else {
                this.$message.error(res.msg);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.login {
    text-align: center;
    .form {
        width: 500px;
        margin: 0 auto;
    }
}
</style>
