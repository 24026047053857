<template>
    <div class="view-wrap">
        <div class="view-title">联系我们</div>
        <div class="content">
            <p>
                通讯地址：上海市奉贤区沿钱公路5601号1幢<br />
                <span style="color: #d7d7d7">
                    --------------------------------------------------------------------------------------------------------------------------------
                </span>
                <br />
                联系人：赵小姐
                <br />
                <span style="color: #d7d7d7">
                    --------------------------------------------------------------------------------------------------------------------------------
                </span>
                <br />
                联系电话: 010-55138742 &nbsp;&nbsp;&nbsp; 18958044793
                <br />
                <span style="color: #d7d7d7">
                    --------------------------------------------------------------------------------------------------------------------------------
                </span>
                <br />
                <br />
                联系电话工作时间为周一至周日
                <br />
                我们将随时为您献上真诚的服务。
                <br /><br />
            </p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
