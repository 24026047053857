<template>
    <div class="view-wrap">
        <div class="view-title">
            <span>{{ type }}漫画</span>
            <div class="line"></div>
        </div>
        <el-card>
            <h3 style="text-align: center">第 {{ activeIndex }} 章</h3>

            <img :src="`${$cdn}image/manhua/${type}/${type}${activeIndex}.jpg`" />

            <div class="ctl-nav">
                <button v-if="showPre" class="btn" @click="handlePrev">上一章</button>
                <button class="btn-3d" @click="handleNext">下一章</button>
            </div>
        </el-card>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            type: "",
            activeIndex: 1,
        };
    },
    computed: {
        ...mapState(["isLogin"]),
        showPre() {
            return this.activeIndex > 1;
        },
    },
    watch: {
        $route: {
            handler(route) {
                this.type = route.params.type;
                this.activeIndex = 1;
                document.querySelector("#app").scrollTop = 0;
            },
            immediate: true,
        },
    },
    methods: {
        handlePrev() {
            if (!this.isLogin) {
                this.$message.info("请先登录");
                this.$router.push("/login");
            } else {
                this.activeIndex--;
                if (this.activeIndex < 1) {
                    this.activeIndex = 1;
                }
                document.querySelector("#app").scrollTop = 0;
            }
        },
        handleNext() {
            if (!this.isLogin) {
                this.$message.info("请先登录");
                this.$router.push("/login");
            } else {
                if (this.activeIndex > 3) {
                    this.activeIndex = 3;
                } else if (this.activeIndex === 3) {
                    this.$message.info("升级会员以继续观看");
                    this.$router.push("/charging_standards");
                } else {
                    this.activeIndex++;
                }
                document.querySelector("#app").scrollTop = 0;
            }
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.view-wrap {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    overflow: auto;
    .view-title {
        color: #997;
        padding: 30px 0;
        font-size: 30px;
    }
    img {
        width: 100%;
    }
    .ctl-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        .btn {
            background: #db4e4f;
            height: 48px;
            width: 80px;
            outline: none;
            border: none;
            border-radius: 5px;
            color: white;
            font-size: 16px;
            cursor: pointer;
            margin: 0 20px;
            &:hover {
                background: #b63131;
            }
        }
    }
}
</style>
