import Vue from "vue";
import VueRouter from "vue-router";

import HomeView from "../views/HomeView.vue";
import ProductsView from "../views/ProductsView.vue";
import EmojisView from "../views/EmojisView.vue";
import ComicsView from "../views/ComicsView.vue";
import UserDisputesView from "../views/UserDisputesView.vue";
import ChargingStandardsView from "../views/ChargingStandardsView.vue";
import AnimeInformationView from "../views/AnimeInformationView.vue";
import BusinessCooperation from "../views/BusinessCooperation.vue";
import ContactUsView from "../views/ContactUsView.vue";

import LoginView from "../views/LoginView.vue";
import RegisterView from "../views/RegisterView.vue";
import ProtocolView from "../views/ProtocolView.vue";

import store from "@/store";
import { getCookie } from "@/assets/tools";

Vue.use(VueRouter);

const routes = [
    { path: "/", name: "home", component: HomeView },
    { path: "/products/:type", name: "products", component: ProductsView },
    { path: "/emojis/:type", name: "emojis", component: EmojisView },
    { path: "/comics/:type", name: "comics", component: ComicsView },
    { path: "/user_disputes", name: "user_disputes", component: UserDisputesView },
    { path: "/charging_standards", name: "charging_standards", component: ChargingStandardsView },
    { path: "/anime_information", name: "anime_information", component: AnimeInformationView },
    { path: "/business_cooperation", name: "business_cooperation", component: BusinessCooperation },
    { path: "/contact_us", name: "contact_us", component: ContactUsView },
    { path: "/login", name: "login", component: LoginView },
    { path: "/register", name: "register", component: RegisterView },
    { path: "/protocol", name: "protocol", component: ProtocolView },
];

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (getCookie("bjbk_islogin") == 1) {
        store.dispatch("setLogin");
    }

    next();
});

export default router;
