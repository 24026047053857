<template>
    <div class="cs">
        <div class="ys-vip1">
            <!-- 周会员10块，月会员25，季会员60，年会员180 -->
            <table>
                <tr>
                    <td style="border-right: 1px solid #e3e3e3">
                        <p>
                            <img :src="`${$cdn}image/vip1.png`" />
                        </p>
                        <h3>周会员</h3>
                        <p class="nian"><span>10</span>元/周</p>
                        <!-- <p class="tian">全站 <span>5张/天</span> 下载</p> -->
                        <p>
                            <el-link type="primary" @click="handlePay">立即升级</el-link>
                        </p>
                    </td>
                    <td>
                        <p>
                            <img :src="`${$cdn}image/vip2.png`" />
                        </p>
                        <h3>月会员</h3>
                        <p class="nian"><span>25</span>元/月</p>
                        <!-- <p class="tian">全站 <span>10张/天</span> 下载</p> -->
                        <p>
                            <el-link type="primary" @click="handlePay">立即升级</el-link>
                        </p>
                    </td>
                </tr>
            </table>
        </div>
        <div class="ys-vip1">
            <table>
                <tr>
                    <td style="border-right: 1px solid #e3e3e3">
                        <p><img :src="`${$cdn}image/vip3.png`" /></p>
                        <h3>季会员</h3>
                        <p class="nian"><span>60</span>元/季度</p>
                        <!-- <p class="tian">全站 <span>30张/天</span> 下载</p> -->
                        <p>
                            <el-link type="primary" @click="handlePay">立即升级</el-link>
                        </p>
                    </td>
                    <td>
                        <p>
                            <img :src="`${$cdn}image/vip3.png`" />
                        </p>
                        <h3>年费</h3>
                        <p class="nian"><span>180</span>元/年</p>
                        <!-- <p class="tian">全站 <span>50张/天</span> 下载</p> -->
                        <p>
                            <el-link type="primary" @click="handlePay">立即升级</el-link>
                        </p>
                    </td>
                </tr>
            </table>
        </div>

        <el-dialog title="会员充值" :visible.sync="showDialog" width="30%" center>
            <div style="text-align: center">
                <img :src="`${$cdn}image/zfb-pay.jpg`" width="300" />
            </div>

            <div slot="footer" class="dialog-footer"></div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            showDialog: false,
        };
    },
    computed: {
        ...mapState(["isLogin", "user"]),
    },
    methods: {
        handlePay() {
            if (!this.isLogin) {
                this.$router.push("/login");
            } else {
                this.showDialog = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.cs {
    .ys-vip1 {
        width: 900px;
        height: 300px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px 0px #653908;
        border-radius: 8px;
        border: 1px solid #653908;
        cursor: pointer;
        margin: 10px auto;
        background: #ffffff;
        text-align: center;
        padding-top: 20px;
    }
    table {
        width: 100%;
        text-align: center;
    }
}
</style>
