<template>
    <div class="home" data-scroll-container>
        <section data-scroll-section style="background: none">
            <div class="row home-top animate__animated animate__bounceInDown">
                <dynamic-marquee
                    direction="row"
                    :speed="{ type: 'pps', number: 200 }"
                    :hoverPause="false"
                >
                    <div class="marquee-item" :style="{ width: `${200 * 8}px` }">
                        <img v-for="n in 8" :key="n" :src="`${$cdn}image/other/${n + 5}.jpg`" />
                    </div>
                </dynamic-marquee>
            </div>
        </section>
        <section data-scroll-section id="st1">
            <h2 class="row section-title" data-scroll>
                <span>精品动漫</span>
                <div class="line"></div>
                <router-link to="/products/emoji" class="btn-3d">更多</router-link>
            </h2>
            <div
                v-for="(item, index) in emojiTypes"
                :key="`${item.type}-${index}`"
                class="row preview-list"
                :class="getRotateByIndex(index)"
            >
                <router-link
                    v-for="n in 7"
                    :key="`${item.type}-${n}`"
                    :to="`/emojis/${item.type}`"
                    data-scroll
                    :data-scroll-speed="getSpeedByIndex(index)"
                    :data-scroll-direction="getDirByIndex(index)"
                >
                    <img :src="`${$cdn}image/${item.type}/${n}.${item.ext}`" />
                </router-link>
            </div>
        </section>
        <section data-scroll-section id="st2">
            <h2 class="row section-title" data-scroll>
                <span>精品漫画</span>
                <div class="line"></div>
                <router-link to="/products/comic" class="btn-3d">更多</router-link>
            </h2>
            <div class="row manhua-list">
                <dynamic-marquee
                    direction="row"
                    :speed="{ type: 'pps', number: 200 }"
                    :hoverPause="false"
                >
                    <div class="marquee-item" :style="{ width: `${comicTypes.length * 320}px` }">
                        <router-link
                            :to="`/comics/${item}`"
                            v-for="item in comicTypes"
                            :key="`${item}-${Math.random()}`"
                        >
                            <img :src="`${$cdn}image/manhua/${item}/${item}-封面.jpg`" />
                        </router-link>
                    </div>
                </dynamic-marquee>
            </div>
        </section>
        <section data-scroll-section></section>

        <section data-scroll-section>
            <div v-if="0" class="row box1">
                <h1 style="font-size: 24px; padding: 20px; color: #bd362f">辣客熊表情包锦集</h1>
                <p>
                    <img :src="`${$cdn}image/2.png`" alt="辣客熊表情包锦集" />
                </p>
                <p>
                    <img :src="`${$cdn}image/2-1.png`" alt="辣客熊表情包锦集" />
                </p>
                <p style="margin-top: 30px">
                    <router-link to="/emojis/lkx" class="btn-3d">查看更多</router-link>
                </p>
            </div>

            <div v-if="0" class="row box1">
                <h1 style="font-size: 24px; padding: 20px; color: #bd362f">哈米小汪表情包锦集</h1>
                <p>
                    <img :src="`${$cdn}image/3.png`" alt="哈米小汪表情包锦集" />
                </p>
                <p>
                    <img :src="`${$cdn}image/3-1.png`" alt="哈米小汪表情包锦集" />
                </p>
                <p style="margin-top: 30px">
                    <router-link to="/emojis/hmxw" class="btn-3d">查看更多</router-link>
                </p>
            </div>

            <div v-if="0" class="row box1">
                <h1 style="font-size: 24px; padding: 20px; color: #bd362f">玩皮猴表情包锦集</h1>
                <p>
                    <img :src="`${$cdn}image/4.png`" alt="玩皮猴表情包锦集" />
                </p>
                <p>
                    <img :src="`${$cdn}image/4-1.png`" alt="玩皮猴表情包锦集" />
                </p>
                <p style="margin-top: 30px">
                    <router-link to="/emojis/wph" class="btn-3d">查看更多</router-link>
                </p>
            </div>

            <div v-if="0" class="row box1">
                <h1 style="font-size: 24px; padding: 20px; color: #bd362f">羞羞喵表情包锦集</h1>
                <p>
                    <img :src="`${$cdn}image/5.png`" alt="羞羞喵表情包锦集" />
                </p>
                <p>
                    <img :src="`${$cdn}image/5-1.png`" alt="羞羞喵表情包锦集" />
                </p>
                <p style="margin-top: 30px">
                    <router-link to="/emojis/xxm" class="btn-3d">查看更多</router-link>
                </p>
            </div>
        </section>

        <section data-scroll-section>
            <div class="row" style="max-width: none; min-height: 600px">
                <footer-vue />
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import LocomotiveScroll from "locomotive-scroll";
import { mapState } from "vuex";

export default {
    name: "HomeView",
    components: {
        // Swiper,
        // SwiperSlide,
    },
    data() {
        return {
            swiperOption: {
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false, // 操作后是否停止自动滑动
                },
            },
        };
    },
    computed: {
        ...mapState({
            emojiTypes: (state) => state.emojiTypes,
            comicTypes: (state) => state.comicTypes,
        }),
    },
    methods: {
        randomSpeed() {
            return Math.floor(Math.random() * 5);
        },
        getSpeedByIndex(index) {
            if ([3, 5, 9, 10].includes(index)) {
                return this.randomSpeed() * -1;
            }
            return this.randomSpeed();
        },
        getRotateByIndex(index) {
            if ([3].includes(index)) {
                return "h-lt";
            } else if ([4].includes(index)) {
                return "h-rt";
            }
            return "";
        },
        getDirByIndex(index) {
            if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(index)) {
                return "horizontal";
            }
            return "vertical";
        },
    },
    mounted() {
        let scroll = new LocomotiveScroll({
            el: document.querySelector("[data-scroll-container]"),
            name: "scroll",
            repeat: true,
            smooth: true,
        });
    },
};
</script>

<style lang="scss" scoped>
.home {
    section {
        width: 100%;
        text-align: center;
        background: linear-gradient(to right bottom, pink, skyblue, #fa0);
    }
    .row {
        max-width: 1200px;
        margin: 0 auto;
        padding: 10px 0;
    }
    .section-title {
        max-width: 1000px;
        margin: 0 auto;
        color: #997;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        font-size: 38px;
        // background: linear-gradient(45deg, #e9ab97, #d9c5c5);
        padding: 10px 40px;
        border-radius: 10px;
        z-index: 10;
        position: relative;
        a {
            color: salmon;
            text-decoration: none;
        }
        .line {
            flex: 1;
            border-bottom: 1px dashed salmon;
            margin: 0 10px;
        }
    }
    .btn-3d {
        text-decoration: none;
        color: #bd362f;
        font-size: 18px;
        font-weight: bold;
    }
    .box {
        width: 100px;
        height: 100px;
        border: 2px dashed deeppink;
        margin: 10px;
        display: inline-block;
    }
    .h-lt {
        transform: rotate(5deg);
    }
    .h-rt {
        transform: rotate(-10deg);
    }
    .box1 {
        text-align: center;
        margin: 0 auto;
        background: #e3e3e3;
    }
    .home-top {
        height: 200px;
        .marquee-item {
            height: 100%;
            display: flex;
            img {
                height: 100%;
                margin: 0 10px;
            }
        }
    }
    .preview-list {
        padding: 10px;
        min-height: 300px;
        .marquee-wrap {
            width: 100%;
            height: 200px;
            margin: 15px 0;
            .marquee-item {
                height: 100%;
                display: flex;
                padding: 10px 0;
            }
        }
        a {
            display: inline-block;
            width: 200px;
            height: 200px;
            border-radius: 10px;
            padding: 20px;
            margin: 0 10px;
            box-sizing: border-box;
            border: 1px solid #aaa;
            &:hover {
                box-shadow: 0 0 10px salmon;
            }
            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .manhua-list {
        height: 600px;
        .swiper-container {
            height: 500px;
            padding: 50px 0;
        }
        .marquee-item {
            a {
                display: inline-block;
                width: 300px;
                height: 500px;
                margin: 0 10px;
                &:hover {
                    box-shadow: 0 0 10px salmon;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }
    }
}
</style>
