import Vue from "vue";
import Vuex from "vuex";
import { setCookie } from "@/assets/tools.js";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        emojiTypes: [
            { type: "lvbu", title: "小吕布", num: 12, ext: "gif" },
            { type: "flm", title: "风流猫", num: 18, ext: "gif" },
            { type: "hmxw", title: "哈米小汪", num: 24, ext: "gif" },
            { type: "lkx", title: "辣客熊", num: 14, ext: "gif" },
            { type: "mgt", title: "蘑菇头", num: 13, ext: "jpeg" },
            { type: "ml", title: "萌龙", num: 15, ext: "png" },
            { type: "mmm", title: "萌萌喵", num: 11, ext: "gif" },
            { type: "wph", title: "顽皮猴", num: 9, ext: "gif" },
            { type: "xxm", title: "羞羞喵", num: 24, ext: "gif" },
            { type: "xy", title: "小玉", num: 7, ext: "png" },
            { type: "kuhou", title: "酷猴", num: 30, ext: "gif" },
            { type: "xhl", title: "小火龙", num: 7, ext: "gif" },
        ],
        comicTypes: [
            "喵喵修仙路",
            "裂空之鹰",
            "猫咪猎人",
            "觉醒任我行",
            "毒医嫡女",
            "喵喵强盗",
            "偷一个鸡腿",
            "喵喵狩猎前夜",
            "真假镇魔印",
            "晴空鹤鸣",
            "除魔录",
            "疯狂救援",
            "三目神",
            "喵喵狩猎前夜",
            "降妖记",
            "烈火英雄",
            "傲娇世子",
            "消失的贡珠",
            "最美拾荒者",
            "冷血饲养法则",
            "恋爱宝典",
            "黑化的女主",
            "罗曼蒂克爱情史",
            "夜晚静悄悄",
            "超速爱情",
            "相思迷人",
            "寻妖记",
            "枯木",
            "刺杀",
            "交易",
            "飘飘江湖",
            "同门惹不起",
            "桃杏嫁东风",
        ],
        isLogin: false,
        user: "",
    },
    getters: {
        emojiMap(state) {
            let data = {};
            state.emojiTypes.forEach((v) => {
                data[v.type] = v.title;
            });
            return data;
        },
    },
    mutations: {},
    actions: {
        setLogin({ commit, state }) {
            console.log("===== ===== setlogin ===== =====");
            setCookie("bjbk_islogin", "1", 1);
            state.isLogin = true;
            let user = localStorage.getItem("bjbk_user");
            state.user = user;
        },
        logout({ commit, state }) {
            setCookie("bjbk_islogin", "0", -1);
            state.isLogin = false;
        },
    },
    modules: {},
});
